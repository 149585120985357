import React from 'react';

function CardSection() {
  return (
    <section className="container">
      <div className="row">
        {/* カード 1 */}
        <div className="col-md-4">
          <div className="card">
            <img src="./ksa-icon.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">婚活記録アプリ</h5>
              <p className="card-text"><a href="https://apps.apple.com/jp/app/%E5%A9%9A%E6%B4%BB%E8%A8%98%E9%8C%B2%E3%82%A2%E3%83%97%E3%83%AA/id6737812692">App Store で配信中</a></p>
              <p className="card-text"><a href="#/products/konkatsu-stats-app">詳細ページ</a></p>
            </div>
          </div>
        </div>
        {/* カード 2 */}
        <div className="col-md-4">
          <div className="card">
            <img src="./placeholder_liver.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">準備中...</h5>
              <p className="card-text">...</p>
              <p className="card-text">...</p>
            </div>
          </div>
        </div>
        {/* カード 3 */}
        <div className="col-md-4">
          <div className="card">
            <img src="./placeholder_liver.png" className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">準備中...</h5>
              <p className="card-text">...</p>
              <p className="card-text">...</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardSection;
