import React from 'react';
import Navbar from './components/Navbar';
import Header from './components/Header';
import CardSection from './components/CardSection';
import Footer from './components/Footer';
import {　HashRouter, BrowserRouter, Route, Routes, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SitePolicy from './pages/SitePolicy';
import Contact from './pages/Contacts';
import KonkatsuStatsApp from './products/KonkatsuStatsApp';

function App() {
  return (
    <HashRouter>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Home/>
            </>
          } />
          <Route path="/privacy-policy" element={
            <PrivacyPolicy />
          } />
          <Route path="/site-policy" element={
            <SitePolicy />
          } />
          <Route path="/contact" element={
            <Contact />
          } />
          <Route path="/products/konkatsu-stats-app" element={
            <KonkatsuStatsApp />
          } />
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
