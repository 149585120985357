import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="container mt-5">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h2 className="mb-4">Privacy Policy</h2>
                    <h3 className="mb-3">プライバシーポリシー（アプリ）</h3>
                    <p>当アプリでは、以下の種類の情報を収集する場合があります。</p>
                    <ul className="mb-4">
                        <li>個人情報：アカウント作成時、サポートへの問い合わせ時に必要な情報（氏名、メールアドレスなど）。</li>
                        <li>使用情報：アプリの使用状況、アクセスした機能やページ、滞在時間、クラッシュ情報などの使用データ。</li>
                        <li>位置情報（必要な場合）：ユーザーが許可した場合、位置情報サービスを利用して位置情報を取得することがあります。</li>
                        <li>デバイス情報：デバイスの種類、オペレーティングシステムのバージョン、IPアドレスなどのデバイスに関する情報。</li>
                    </ul>
                    <p>これらの情報は以下の目的で利用されます。</p>
                    <ul className="mb-4">
                        <li>アプリの機能提供、パーソナライズされたユーザー体験の提供</li>
                        <li>広告の配信</li>
                        <li>サポートやカスタマーサービスの提供</li>
                        <li>アプリの利用分析、改善、機能追加</li>
                        <li>必要に応じた通知の送信（アプリ更新情報、重要なお知らせ等）</li>
                    </ul>
                    <p>当社は、以下の条件を除き、ユーザーの個人情報を第三者と共有することはありません。</p>
                    <ul className="mb-4">
                        <li>ユーザーの同意を得た場合</li>
                        <li>法的義務を遵守するため、または法的請求や訴訟に応じるため</li>
                        <li>サービス提供に必要なパートナー企業との共有（例：データ分析、ホスティングサービス）</li>
                    </ul>
                    <p>ユーザーの個人情報は安全に保管し、不正アクセスやデータの漏洩を防止するための適切なセキュリティ対策を講じています。</p>
                    <p>データの保管期間は、法律で定められた期間または利用目的に必要な期間とします。</p>
                    <p>ユーザーは、自身の個人情報について以下の権利を有しています：</p>
                    <ul className="mb-4">
                        <li>情報の確認と修正：登録された個人情報の確認および修正</li>
                        <li>削除依頼：アカウント削除に伴う個人情報の削除を要請</li>
                        <li>オプトアウト：マーケティング目的でのデータ使用からの除外</li>
                    </ul>
                    <p>また、必要に応じて本プライバシーポリシーを改訂する場合があります。</p>
                    <p>プライバシーポリシーに関するご質問やお問い合せは <a href="#/contact">Contact</a> までご連絡ください。</p>
                    <h3 className="mt-5 mb-3">アクセス解析について（当ウェブサイト）</h3>
                    <p>当ウェブサイト（iztn.dev およびそのサブドメインを含む）では Google Analytics を使用しアクセス解析を行っています。Google Analytics はトラフィックデータ収集のために Cookie を使用しています。このトラフィックデータは匿名で取集されており、個人を特定するものではありません。Cookie を無効にすることでトラフィックデータの収拾を拒否することができます。この規約に関しての詳細は<a href="https://marketingplatform.google.com/about/analytics/terms/jp/">Googleアナリティクスサービス利用規約のページ</a>や <a href="https://policies.google.com/?hl=ja">Googleポリシーと規約のページ</a>をご覧ください。</p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
