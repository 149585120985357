import React from 'react';
import { BsTwitterX } from "react-icons/bs";
import { SiGoogleforms } from "react-icons/si";

function Contact() {
    return (
        <div className="container mt-5">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h2 className="card-title">Contact Us</h2>
                    <div className="container mt-4">
                        <p className="lead">お問い合わせは開発者の X アカウント、またはお問い合わせフォームにお願いいたします。</p>
                        <p>For inquiries, please contact the developer via the X account or the contact form.</p>
                        <p>※ お問い合わせフォームにいただいた回答はお時間をいただく可能性が高いです。</p>
                        <div className="d-flex justify-content-center mt-4">
                            <div className="m-2">
                                <a href="https://x.com/akixjp" target="_blank" rel="noopener noreferrer">
                                    <BsTwitterX size={50} color='black' />
                                </a>
                            </div>
                            <div className="m-2">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSd1a38tJ_eYTHxiPX1lEIdaeGBWSI6BqXR5_nTM-IrEEO6IXQ/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                                    <SiGoogleforms size={50} color='black' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
