import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function MyNavbar() {
    return (
       <Navbar bg="light" expand="lg" >
                <Navbar.Brand href="/" style={{ marginLeft: 20 }}>iztn.dev</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="/" style={{ marginLeft: 20 }}>Home</Nav.Link>
                        <Nav.Link href="#/privacy-policy" style={{ marginLeft: 20 }}>Privacy Policy</Nav.Link>
                        <Nav.Link href="#/site-policy" style={{ marginLeft: 20 }}>Site Policy</Nav.Link>
                        <Nav.Link href="#/contact" style={{ marginLeft: 20 }}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    );
}

export default MyNavbar;
