import React from 'react';

function Header() {
  return (
    <header className="jumbotron text-center">
      <h1 className="display-4">iztn.dev</h1>
      <p className="lead"></p>
      {/* <a href="#" className="btn btn-primary btn-lg">Learn More</a> */}
    </header>
  );
}

export default Header;
