import React from 'react';
import Header from '../components/Header';
import CardSection from '../components/CardSection';

function SitePolicy() {
    return (
        <div className="container mt-5">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h3 className="card-title">免責事項</h3>
                    <p className="card-text">
                        本ウェブサイト（iztn.dev およびそのサブドメインを含む）に掲載された情報によって発生したトラブルや損害等に対しての一切の責任を負いません。<br/>
                        また、本サイトに記載している内容は予告なく修正や変更を行うことがあります。
                    </p>
                    <h3 className="card-title">著作権</h3>
                    <p className="card-text">
                        本ウェブサイトが提供するすべてのコンテンツの著作権を保持しており、著作権法その他各種条約・法律などで保護されています。
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SitePolicy;