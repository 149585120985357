import React from 'react';
import Header from '../components/Header';
import CardSection from '../components/CardSection';

function Home() {
  return (
    <div>
        <div className="container mt-5">
            <Header />
        </div>
        <div className="mt-5">
            <CardSection />
        </div>
    </div>
  );
}

export default Home;
