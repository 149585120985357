import React from 'react';
import './products_styles.css';

function KonkatsuStatsApp() {
  return (
    <div className="container mt-5">
      <div className="card shadow-sm">
        <div className="card-body">
          <h2 className="card-title text-center">婚活記録アプリ</h2>
          <div className="container mt-4 text-center">
            <img src="./ksa-icon.png" className="card-img-detail" alt="婚活記録アプリのアイコン" />
            <p className="card-text"><a href="https://apps.apple.com/jp/app/%E5%A9%9A%E6%B4%BB%E8%A8%98%E9%8C%B2%E3%82%A2%E3%83%97%E3%83%AA/id6737812692">App Store で配信中</a></p>
          </div>
          <div className="container mt-4 text-center">
          <p>主に結婚相談所で婚活中の方を対象にしたアプリです。</p>
          <p>婚活は長く苦しいかもしれませんが、記録はあなたを支えます。<br/>
          是非このアプリであなたの活動を記録してみてください。<br/>
          このアプリは単にメモを取るだけでなく、５つまで設定できる「こだわり条件」でお相手を比較したり、分析したりすることができます。</p>
          <p>また、このアプリは入力データを外部サーバーに送らないので、安心してご利用いただけます。<br/>
          ただし、バックアップはしっかりとお願いします（アプリを削除しても全データが消去されますのでご注意下さい）。</p>
          <p>
          このアプリがあなたのお役に立てると嬉しいです。<br/>
          ご感想・ご要望・コメントなどもお待ちしております。
          </p>
          </div>
          <div className="image-row">
            <img src="./screenshot1.png" className="screenshot-image" alt="スクリーンショット1" />
            <img src="./screenshot2.png" className="screenshot-image" alt="スクリーンショット2" />
            <img src="./screenshot3.png" className="screenshot-image" alt="スクリーンショット3" />
            <img src="./screenshot4.png" className="screenshot-image" alt="スクリーンショット4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default KonkatsuStatsApp;
